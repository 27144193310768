/* NAVBAR CONFIGURATION PER SOLUTION */

.navbar-studio {
  --solution-background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.86),
    rgb(0, 0, 0, 1)
  );
  --solution-label-color: #00d27a;
  --solution-foreground-color: white;
}
.navbar-devkit {
  --solution-background-image: linear-gradient(
    -45deg,
    rgba(0, 160, 255, 0.86),
    #0048a2
  );
  --solution-label-color: #00d27a;
  --solution-foreground-color: white;
}
.navbar-defender {
  --solution-background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.86),
    rgb(0, 0, 0, 1)
  );
  --solution-label-color: #00d27a;
  --solution-foreground-color: white;
}

/* NAVBAR STUDIO STYLES */

.navbar-collapse {
  background-image: var(--solution-background-image) !important;
}
.navbar-vertical-label {
  color: var(--solution-label-color) !important;
}
.navbar-vertical-divider {
  color: var(--solution-foreground-color) !important;
  opacity: 0.2 !important;
}
.nav-link-text,
.nav-link-icon {
  color: var(--solution-foreground-color);
  opacity: 0.75 !important;
}
.dropdown-indicator::after {
  border-color: var(--solution-foreground-color) !important;
  opacity: 1 !important;
}
.nav-link:hover {
  .nav-link-text,
  .nav-link-icon {
    color: var(--solution-foreground-color);
    opacity: 1 !important;
  }
}
.nav-link.active .nav-link-text,
.nav-link.active .nav-link-icon {
  color: var(--solution-foreground-color);
  opacity: 1 !important;
}
